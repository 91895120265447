<template>

  <div>

    <add-new-project
      :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      :project-data="project"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewProjectSidebarActive = true"
              >
                <span class="text-nowrap">Добавить проект</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <div class="relative">
        <b-overlay
          :show="loading"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchProjects"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Нет данных"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(createdAt)="data">
            <span class="text-nowrap">
              {{ data.item.createdAt | moment("DD MMMM GGGG") }}
            </span>
          </template>

          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media v-if="data.item.user" vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.user.avatar && `${server}/${data.item.user.avatar}`"
                  :text="avatarText(data.item.user.fio)"
                  variant="light-primary"
                  :to="{ name: 'user-view', params: { id: data.item.user.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'user-view', params: { id: data.item.user.id } }"
                class="font-weight-bold"
              >
                {{ data.item.user.fio }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-link
              :to="{ name: 'project-view', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              {{ data.item.name }}
            </b-link>
          </template>

          <template #cell(channels)="data">
            <div>
              <b-link
                v-for="(channel, key) in data.item.channels"
                :key="key"
                class="font-weight-bold"
                @click="doCopy(`https://lk.trafficstats.ru/report-form/${data.item.id}/${channel}`)"
              >
                <span>{{ channel }}</span> <feather-icon icon="CopyIcon" />{{ key !== data.item.channels.length - 1 ? ', ' : ''}}
              </b-link>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item :to="{ name: 'project-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t('Details') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="editProject(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteProject(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown,
  BDropdownItem, BPagination, BOverlay,
  BButton, BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useProjectsList from './useProjectsList'
import projectsStoreModule from '../projectsStoreModule'
import AddNewProject from './AddNewProject.vue'

export default {
  components: {
    AddNewProject,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,

    vSelect,
    BOverlay,
  },
  setup(props, ctx) {
    const APP_STORE_MODULE_NAME = 'app-projects'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, projectsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const server = process.env.VUE_APP_SERVER

    const {
      isAddNewProjectSidebarActive,
      loading,
      project,
      fetchProjects,
      editProject,
      deleteProject,
      doCopy,
      tableColumns,
      channels,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    } = useProjectsList(ctx)

    return {
      server,

      isAddNewProjectSidebarActive,
      loading,
      project,

      fetchProjects,
      editProject,
      deleteProject,
      doCopy,
      tableColumns,
      channels,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
