<template>
  <b-sidebar
    id="add-new-project-sidebar"
    :visible="isAddNewProjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-project-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{projectData.id ? 'Обновить проект' : 'Добавить проект'}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-overlay
            :show="$store.state.app.loadingCreate"
            no-wrap
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
          />

          <validation-provider
            v-if="$store.state.app.user.role === 'admin'"
            #default="validationContext"
            name="Клиент"
            rules="required"
          >
            <b-form-group
              label="Клиент"
              label-for="user"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projectData.user ? [...users, projectData.user] : users"
                :clearable="false"
                :filterable="false"
                input-id="user"
                :reduce="val => val.id"
                :get-option-label="option => option.fio"
                @search="searchUser"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template
                    v-if="searching"
                  >
                    По запросу <em>{{ search }}</em> ничего не найдено.
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Начните вводить текст, чтобы найти пользователя.</em>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Название"
            rules="required"
          >
            <b-form-group
              label="Название"
              label-for="name"
            >

              <b-form-input
                id="name"
                v-model="projectData.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Каналы"
            rules="required"
          >
            <b-form-group
              label="Каналы"
              label-for="channels"
            >

              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="projectData.channels"
                :state="getValidationState(validationContext)"
                :clearable="false"
                multiple
                taggable
                push-tags
                :options="channelsOptions"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="KPI Тип"
          >
            <b-form-group
              label="KPI Тип"
              label-for="kpi_type"
            >
              <v-select
                v-model="projectData.kpi_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="kpiTypeOptions"
                :clearable="true"
                input-id="kpi_type"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="KPI Значение"
          >
            <b-form-group
              label="KPI Значение"
              label-for="kpi_sum"
            >
              <b-form-input
                id="kpi_sum"
                v-model="projectData.kpi_sum"
                type="number"
                trim
              />
             <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- <b-form-group
            label="Поля"
            label-for="projectFields"
          >
            <form-repeater-fields
              id="projectFields"
              :project-data="projectData"
              :fields-options="fieldsOptions"
            />
          </b-form-group> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{projectData.id ? 'Обновить' : 'Добавить'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import store from '@/store'
// import FormRepeaterFields from './FormRepeaterFields.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    vSelect,
    // FormRepeaterFields,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProjectSidebarActive',
    event: 'update:is-add-new-project-sidebar-active',
  },
  props: {
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankProjectData = {
      name: '',
      user_id: '',
      user: null,
      channels: ['Вконтакте'],
      kpi_type: '',
      kpi_sum: '',
      /* eslint-disable */
      fields: [
        { key: 'createdAt', sortable: true, label: 'Дата', thClass: 'text-left', editable: false },
        { key: 'user', sortable: true, label: 'Клиент', thClass: 'text-left' },
        { key: 'channel', sortable: true, label: 'Канал', thClass: 'text-left' },
        { key: 'spent', sortable: true, label: 'Потрачено', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
        { key: 'coverage', sortable: true, label: 'Охват/Показы', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
        { key: 'clicks', sortable: true, label: 'Клики', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
        { key: 'leads', sortable: true, label: 'Лиды', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
        { key: 'validLeads', sortable: true, label: 'Валидных Лидов', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
        { key: 'cpc', sortable: true, label: 'СРС', thClass: 'text-left', tdClass: 'text-left', editable: false },
        { key: 'ctr', sortable: true, label: 'CTR', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'cv', sortable: true, label: 'CR клик > лид', thClass: 'text-left', tdClass: 'text-left', editable: false },
        { key: 'cpl', sortable: true, label: 'CPL', thClass: 'text-left', tdClass: 'text-left', editable: false },
        { key: 'cv2', sortable: true, label: 'CR лид > вал. лид', thClass: 'text-left', tdClass: 'text-left', editable: false },
        { key: 'cpvl', sortable: true, label: 'CPVL', thClass: 'text-left', tdClass: 'text-left', editable: false },
      ],
      /* eslint-enable */
    }

    const channelsOptions = [
      'Вконтакте',
      'Яндекс.Директ',
      'Одноклассники',
      'Google Adwords',
      'Instagram',
      'Facebook',
      'TikTok',
      'Youtube',
      'Оффлайн',
    ]

    const resetprojectData = () => {
      /* eslint-disable */
      props.projectData.id = null,
      props.projectData.user_id = blankProjectData.user_id
      props.projectData.user = blankProjectData.user
      props.projectData.name = blankProjectData.name
      props.projectData.channels = blankProjectData.channels
      props.projectData.kpi_type = blankProjectData.kpi_type
      props.projectData.kpi_sum = blankProjectData.kpi_sum
      props.projectData.fields = blankProjectData.fields
      /* eslint-enable */
    }

    const kpiTypeOptions = [
      'Цена лида',
      'Количество лидов',
      'Рекламный бюджет',
      'Конверсия в Лид',
    ]

    /* eslint-disable */
    const fieldsOptions = ref([
      { key: 'createdAt', sortable: true, label: 'Дата', thClass: 'text-left', editable: false },
      { key: 'user', sortable: true, label: 'Клиент', thClass: 'text-left' },
      { key: 'channel', sortable: true, label: 'Канал', thClass: 'text-left' },
      { key: 'spent', sortable: true, label: 'Потрачено', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
      { key: 'coverage', sortable: true, label: 'Охват/Показы', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
      { key: 'clicks', sortable: true, label: 'Клики', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
      { key: 'leads', sortable: true, label: 'Лиды', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
      { key: 'validLeads', sortable: true, label: 'Валидных Лидов', thClass: 'text-left', tdClass: 'text-left', editable: true, type: 'number' },
      { key: 'cpc', sortable: true, label: 'СРС', thClass: 'text-left', tdClass: 'text-left', editable: false },
      { key: 'ctr', sortable: true, label: 'CTR', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'cv', sortable: true, label: 'CR клик > лид', thClass: 'text-left', tdClass: 'text-left', editable: false },
      { key: 'cpl', sortable: true, label: 'CPL', thClass: 'text-left', tdClass: 'text-left', editable: false },
      { key: 'cv2', sortable: true, label: 'CR лид > вал. лид', thClass: 'text-left', tdClass: 'text-left', editable: false },
      { key: 'cpvl', sortable: true, label: 'CPVL', thClass: 'text-left', tdClass: 'text-left', editable: false },
    ])
    /* eslint-enabled */

    const users = ref([])

    const searchUser = debounce(v => {
      if (v) {
        store.dispatch('app-projects/fetchUsers', {
          params: {
            filter: JSON.stringify({
              role: 'client',
              fio: v ? {
                $regex: v,
                $options: 'i',
              } : undefined,
            }),
          },
        })
          .then(response => {
            const { data } = response.data
            users.value = data
          })
          .catch(() => {
            users.value = []
          })
      }
    }, 500)

    const onSubmit = () => {
      if (props.projectData.id) {
        store.dispatch('app-projects/updateProject', {
          id: props.projectData.id,
          data: {
            user_id: props.projectData.user_id,
            name: props.projectData.name,
            channels: props.projectData.channels,
            kpi_type: props.projectData.kpi_type,
            kpi_sum: props.projectData.kpi_sum,
            fields: props.projectData.fields,
          },
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-project-sidebar-active', false)
          })
      } else {
        if (store.state.app.user.role === 'client') {
          /* eslint-disable */
          props.projectData.user_id = store.state.app.user.id
          /* eslint-enable */
        }
        store.dispatch('app-projects/addProject', props.projectData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-project-sidebar-active', false)
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetprojectData)

    return {
      searchUser,
      users,
      channelsOptions,
      kpiTypeOptions,
      fieldsOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
